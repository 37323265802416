import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
import { mapActions } from "vuex";
import { Getter } from "vuex-class";
import { ComboListOptionsCampaign } from "@/utils/resolveObjectArray";

// Component definition
@Component({
  components: {
    CardAutocomplete,
    ButtonTooltip,
  },
  methods: {
    ...mapActions("campaign", { fetchStoreAttributions: "getStoreAttributions" }),
  },
})
export default class SelectorCampaign extends Vue {
  // Props
  @Prop({ required: true }) private value!: any;
  @Prop({ default: () => [] }) readonly rules!: any[];
  @Prop({ default: "name" }) readonly itemText!: string;
  @Prop({ default: "external_id" }) readonly itemValue!: string;
  @Prop({ default: "" }) readonly label!: string;
  @Prop({ default: "" }) readonly hint!: string;
  @Prop({ default: "" }) readonly reference!: string;
  @Prop({ default: "" }) readonly placeholder!: string;
  @Prop({ default: true }) readonly required!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: null }) readonly errorMessages!: string;
  @Prop({ default: false }) readonly multiple!: boolean;
  @Prop({ default: true }) readonly chips!: boolean;
  @Prop({ default: true }) readonly returnObject!: boolean;
  @Prop({ default: false }) readonly hideDetails!: boolean;
  @Prop({ default: null }) readonly appendOuterData!: any;
  @Prop({ default: true }) readonly smallChips!: boolean;
  @Prop({ default: true }) readonly colapseSelection!: boolean;
  @Prop({ default: true }) readonly persistentHint!: boolean;
  @Prop({ default: true }) readonly persistentPlaceholder!: boolean;
  @Prop({ default: false }) readonly clearable!: boolean;

  @Getter("campaign/getCampaignList") getItems!: ComboListOptionsCampaign[];

  // Data
  public isLoading: boolean = false;

  // Computed Property for value
  get computedValue() {
    return this.value;
  }

  set computedValue(value: any) {
    this.$emit("input", value);
  }

  // Actions
  public fetchStoreAttributions!: () => Promise<any>;

  // Method to trigger campaign data fetching
  private async loadCampaignData() {
    this.setLoadingState(true);
    try {
      await this.fetchStoreAttributions();
    } catch (error) {
      console.error("Error fetching paginated campaign data:", error);
    } finally {
      this.setLoadingState(false);
    }
  }

  // Helper method to handle loading state
  private setLoadingState(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  // Tooltip configuration
  public tooltipData() {
    return {
      icon: "mdi-information-outline",
      text: this.$t(`${this.label}`),
      to: "",
      right: true,
    };
  }

  // Handle actions triggered from UI
  public async handleAction(action: string) {
    if (action === "focus") {
      await this.loadCampaignData();
    }
  }
}
