import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import Separator from "@/components/Content/Separator.vue";
import TabSubMenu from "@/views/Admin/Commons/TabSubMenu.vue";
import ReportForm from "@/views/Admin/Reports/V2/Form/ReportForm.vue";
import ReportScheduler from "@/views/Admin/Reports/V2/ReportScheduler/index.vue";
import { GetItemsTabs, GetConditionalMetrics } from "@/views/Admin/Reports/V2/utils/reportsUtils";
import { preparedFiltersEntitys, prepareFilterToSP } from "@/utils/reportData";
import { debounce, first, isEmpty, isObject, orderBy } from "lodash";
import { mapActions, mapGetters } from "vuex";
import { TypeLoading } from "@/interfaces/loading";
import { getError } from "@/utils/resolveObjectArray";
import { FilterData, ReportDataEntity, ResourceDataEntity } from "@/interfaces/report";
import {
  getFilteredDimensions,
  getFilteredMetrics,
  getFilteredSplits,
  getFilteredArray,
  getParsedData,
  hasProperties,
} from "@/models/Reports/Report";
import { isProd } from "@/services/process-service";
import { ReportResourceDataEntity } from "@/models/Reports/v2/Report";
import { ScheduleEntity } from "@/models/Reports/v2/Entity";
import ParamService from "@/services/params-service";
import { getDefaultSortingOption, getPaginatedDefault } from "@/utils/filter-global";
import { ResultPaginate } from "@/interfaces/paginated";
import { ResponseReportEntity } from "@/models/Reports/v2/GenerateReport";
import { assignSortedResource, filterTrueProperties, getProperty, NestedObject } from "./utils";

export default Vue.extend({
  name: "MainReport",
  props: {},
  components: {
    Alertize,
    Separator,
    TabSubMenu,
    ReportForm,
    ReportScheduler,
  },
  data: () => ({
    currentTab: 0,
    items: GetItemsTabs(),
    filterEntitys: preparedFiltersEntitys(),
    conditionalMetrics: GetConditionalMetrics(),
    account: null,
    accountSelected: null,
    schedulerSelected: null,
  }),
  created() {
    this.$nextTick(async () => {});
  },
  async mounted() {
    this.$nextTick(async () => {
      this.getPaginated(false).then(() => {
        // if (this.getSchedulerReport.length > 0) {
        // 	this.enabledTabScheduler();
        // }
      });
    });
  },
  async beforeDestroy() {
    await this.clearAllFiltersSP();
  },
  computed: {
    ...mapGetters("report_v2", ["storeReport", "storeSchedule", "storeResources", "storeResourceData"]),
    ...mapGetters("generate_report", ["attempResponse"]),
    ...mapGetters("account", ["isAccountMaster"]),
    ...mapGetters("profile", { _account: "account" }),

    getStoreReport(): ReportDataEntity {
      return this.storeReport;
    },
    getStoreSchedule(): ScheduleEntity {
      return this.storeSchedule;
    },
    getStoreResources(): ResourceDataEntity {
      return this.storeResources;
    },
    getStoreResourceData(): ReportResourceDataEntity {
      return this.storeResourceData;
    },
    getAttempResponse(): ResponseReportEntity {
      return this.attempResponse;
    },

    getResultPaginate(): ResultPaginate {
      return this.$store.state.report.result_paginate;
    },

    getSchedulerReport(): any[] {
      const result: ResultPaginate | undefined = this.getResultPaginate;
      if (!result || !result.data) return [];
      return result.data;
    },

    getErrors() {
      return this.$store.state.proccess.errors;
    },
    getExternalIDAccount() {
      return this.account?.external_id;
    },
  },
  methods: {
    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("proccess", ["setLoadingField"]),
    ...mapActions("account", ["getAccount"]),
    ...mapActions("profile", ["isAccountAuthorized"]),
    ...mapActions("report", [
      "getDimMet",
      "getAllReportType",
      "getSchedulerTypes",
      "getDimensionAndMetricSP",
      "getFilterSP",
      "getAccountSP",
      "getSchedulerPaginated",
    ]),
    ...mapActions("report_v2", ["setState"]),
    ...mapActions("campaign", ["setCommitData"]),

    /**
     * Obtener el listado paginado de los reportes programados
     * @param enableLoading
     */
    async getPaginated() {
      try {
        const response = await this.getSchedulerPaginated(
          await ParamService.getParams(getPaginatedDefault(1, 5), {}, getDefaultSortingOption("id")),
        );
        // Guardar en el store
        //this.setState({ key: "table_report", value: response.data });
      } catch (error) {}
    },

    updateSelectedTabIndex(index: number) {
      this.currentTab = index;
    },

    currentTabSelected(item: number, selected: number) {
      return item === selected;
    },

    getError(index: any) {
      return getError(this.getErrors, index);
    },

    async fetchFilters(entity: string, accounts: any) {
      this.getStoreResourceData.loading[entity] = true;
      let data = {
        type: this.filterEntitys[entity].type,
        custom_key: this.filterEntitys[entity].key,
        accounts,
      };
      if (this.filterEntitys[entity].requireFilter) {
        data["filter"] = await this.getFilterForEntity(this.filterEntitys[entity].filter);
      }
      const result = await this.dispatchSearch(data);
      this.getStoreResources[this.filterEntitys[entity].resourceName] = result;
      this.getStoreResourceData.loading[entity] = false;
    },

    async getFilterForEntity(entity: string) {
      return this.getStoreReport.filters[entity].map(f => f.id);
    },

    async fetchAccountsSP() {
      const loadAccount = "account";
      this.getStoreResourceData.loading[loadAccount] = true;
      const account = await this.getAccount();
      await this.getAccountSP()
        .then(res => {
          this.getStoreResources.account = res;
          if (!this.getStoreResources.account.some(c => Number(c.key) == account.external_id)) {
            this.getStoreResources.account.push({
              key: account.external_id,
              value: account.name,
              type: "account",
            });
          }
          this.getStoreResourceData.loading[loadAccount] = false;
        })
        .catch(err => {
          this.getStoreResourceData.loading[loadAccount] = false;
        });
    },

    // fetching SP
    async fetchFilterSP(params: any) {
      this.getStoreResourceData.loading[params] = true;
      const payload = await prepareFilterToSP({
        filters: this.getStoreReport.filters,
        data_range: this.getStoreReport?.data_range,
        start_date: this.getStoreReport?.start_date,
        end_date: this.getStoreReport?.end_date,
        report_type: this.getStoreReport?.report_type,
        report_year: this.getStoreReport?.report_year,
        omit: params,
      });
      await this.getFilterSP(payload)
        .then(async res => {
          this.getStoreResourceData.loading[params] = false;
          if (isObject(res)) {
            /**
             * Keys de los filtros obtenidos del response
             */
            const responseKeys = [
              "account",
              "advertiser",
              "campaign",
              "line_item",
              "creative",
              "deal",
              "layer_name",
              "poi_layer_name",
              "ooh_layer_name",
              "ooh_distance",
            ];

            /**
             * Match de los keys de los filtros con los keys de los recursos en el store de [storeResources]
             */
            const matchResources = {
              account: "account",
              advertiser: "advertisers",
              campaign: "campaigns",
              line_item: "line_items",
              creative: "creatives",
              deal: "deal_id",
              layer_name: "layer_name",
              poi_layer_name: "layer_name",
              ooh_layer_name: "ooh_layer_name",
              ooh_distance: "ooh_distance",
            };

            responseKeys.forEach(resource => {
              if (resource === "account") {
                if (!this.isAccountMaster) {
                  /**
                   * Se setean los account si no es una cuenta master
                   */
                  this.storeResources.setData("account", assignSortedResource("account", getProperty(res, "account")));
                }
              } else {
                /**
                 * Si el recurso es `ooh_distance y el layer_name no esta seleccionado, no se setea el recurso `ooh_distance
                 */
                if (resource === "ooh_distance" && !this.getStoreReport.filters.ooh_layer_name) {
                  this.storeResources.setData("ooh_distance", []);
                  return;
                }
    
                const value = getProperty(res, resource);
                /*
                * verifica si no hay datos
                */  
                if (value?.length === 0) {
                  return;
                }
                this.storeResources.setData(matchResources[resource], assignSortedResource(resource, value));
              }
            });
          }
        })
        .catch(async err => {
          this.getStoreResourceData.loading[params] = false;
        });
    },

    async fetchResource(params: {
      resource: string;
      field: string;
      addParams: boolean;
      custom_key: string;
      custom_value: string;
    }) {
      if (!isEmpty(this.getStoreResources[params.field])) return;

      try {
        await this.setLoadingData(TypeLoading.loading);

        const result: Array<any> = await this.dispatchSearch({
          type: params.resource,
          return_object: false,
          ...params,
        });

        this.getStoreResources.setData(params.field, result);

        this.getStoreReport.setData(params.field, first(result)?.id || "");

        if (params.field === "type") {
          /**
           * Guardar en resource de schedule: file_type
           */
          this.storeSchedule.resource.setResource("file_type", result);
        }

        if (params.field == "data_range") {
          this.getStoreReport.setData(params.field, "last_7_days");
          const filtered = this.getStoreResources[params.field].filter(d => d.id != "today");
          this.getStoreResources.setData(params.field, filtered);
        }
        await this.setLoadingData();
      } catch (error) {
        await this.setLoadingData();
      }
    },

    async fetchFields(params: any) {
      const _prepare = {
        report_type: this.getStoreReport.report_type,
        type: "report_fields",
        custom_key: "name",
        custom_value: "description",
        ...params,
      };

      await this.setLoadingData(TypeLoading.loading);

      const result = await this.dispatchFields(_prepare);

      this.getStoreResources.setData(params.field_type, result);

      await this.setLoadingData();
    },

    async fetchAllReportType() {
      await this.setLoadingData(TypeLoading.loading);

      await this.getAllReportType()
        .then(res => {
          this.getStoreResources.setData("report_all_type", res);
          this.setLoadingData();
        })
        .catch(err => {
          this.setLoadingData();
        });
    },

    /**
     * @deprecated The method should not be used
     */
    async fetchDimMet(params: any) {
      const _prepare = {
        report_type: this.getStoreReport.report_type,
        ...params,
      };
      await this.setLoadingData(TypeLoading.loading);
      await this.getDimMet(_prepare)
        .then(res => {
          this.getStoreResources.setData(params.field_type, res);
          this.setLoadingData();
        })
        .catch(err => {
          this.setLoadingData();
        });
    },

    async fetchDimensionAndMetrics() {
      let filter: any = {
        report_type: this.getStoreReport.report_type,
        year: this.getStoreReport.report_year,
      };

      const layerName: any = this.getStoreReport.filters.layer_name;

      if (this.getStoreReport.isOoh()) {
        filter.poi_layer_id = layerName?.key ? Number(layerName?.key) : undefined;
      }

      this.getStoreResourceData.loading["dimensions"] = true;
      this.getStoreResourceData.loading["metrics"] = true;

      this.getStoreResources.dimensions = {};
      this.getStoreResources.metrics = {};

      this.getStoreReport.dimensions = {};
      this.getStoreReport.metrics = {};

      let accountAuthorized = await this.isAccountAuthorized(this._account.external_id);

      await this.getDimensionAndMetricSP(filter)
        .then(async (res: { dimensions: NestedObject; metrics: NestedObject }) => {
          this.getStoreResourceData.loading["dimensions"] = false;
          this.getStoreResourceData.loading["metrics"] = false;

          /**
           * Filtrar las dimensiones y metricas solo si es un reporte de ooh
           * Se filtra para que solo se muestren las dimensiones y metricas que tengan true
           */
          if (filter.report_type === "ooh_report") {
            res.dimensions = filterTrueProperties(res.dimensions);
            res.metrics = filterTrueProperties(res.metrics);
          }

          this.getStoreResources.setData("dimensions", res.dimensions);

          let METRICS: { [x: string]: any } = Object.create(null);

          if (accountAuthorized) {
            METRICS = res.metrics;
          } else {
            const { campaign_spend, ...metricRest } = res.metrics;
            METRICS = metricRest;
          }

          if (METRICS.distance !== undefined && METRICS.distance.less_50_mts !== undefined) {
            METRICS.distance = await this.sortPropertiesByNumber(METRICS.distance);

            const distance = {};

            for (const [key, value] of Object.entries(METRICS.distance)) {
              // distance[key] = key === "less_50_mts" ? 1 : 0;
              distance[key] = 0;
            }

            this.storeReport.setData("metrics", distance);
          }

          this.getStoreResources.setData("metrics", METRICS);
        })
        .catch(err => {
          console.error("Error", err);
          this.getStoreResourceData.loading["dimensions"] = false;
          this.getStoreResourceData.loading["metrics"] = false;
        });
    },

    async sortPropertiesByNumber(obj: { [s: string]: unknown } | ArrayLike<unknown>) {
      return Object.entries(obj)
        .sort(([keyA], [keyB]) => {
          const numA = parseInt(keyA.match(/\d+/)?.[0] || "0");
          const numB = parseInt(keyB.match(/\d+/)?.[0] || "0");
          return numA - numB;
        })
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
    },

    async fetchSchedulerTypes(field_type: string) {
      await this.setLoadingData(TypeLoading.loading);

      await this.getSchedulerTypes()
        .then(res => {
          this.getStoreResources.setData(field_type, res);
          this.setLoadingData();
        })
        .catch(err => {
          this.setLoadingData();
        });
    },

    async dispatchFields(params: {
      report_type: string;
      field_type: string;
      type: string;
      custom_key?: string;
      custom_value?: string;
      return_object?: Boolean;
    }) {
      return this.$store.dispatch("report/fields", params);
    },

    async dispatchSearch(params: any) {
      return this.$store.dispatch("report/search", {
        type: params?.type,
        term: this.getStoreResourceData.term[params?.type],
        return_object: params.return_object,
        custom_key: params.custom_key,
        custom_value: params.custom_value,
        account_id: params.account_id,
        filters: params?.filter,
        accounts: params?.accounts,
        addParams: params?.addParams,
      });
    },

    async handleCombo(key: string, eventW: boolean = false) {
      this.getStoreResources.setData(key, []);
      await this.fetchFilterSP(key);
      if (!eventW) this.getStoreResourceData.term[this.filterEntitys[key].type] = "";
    },

    handleTerm(event: any) {
      this.getStoreResourceData.term[event.type] = event.term;
    },

    handleRemove(event: any) {
      let root: Array<string> = ["dimension", "metric"];
      let index = -1;
      if (root.includes(event.type)) {
        index = this.getStoreReport[event.type].indexOf(event.item);
        if (index >= 0) this.getStoreReport[event.type].splice(index, 1);
      } else {
        index = this.getStoreReport.filters[event.type].indexOf(event.item);
        if (index >= 0) this.getStoreReport.filters[event.type].splice(index, 1);
      }
    },

    async clearAllFilters(params: { force?: boolean; dm?: boolean; account?: boolean }) {
      if (!params?.force && this.getStoreSchedule.form.hasID()) return;

      
      this.getStoreReport.campaign = null;
      this.setCommitData({ key: "SET_RESULT_LIST", value: [] });

      this.getStoreReport.filters.advertisers = [];
      this.getStoreReport.filters.line_items = [];
      this.getStoreReport.filters.campaigns = [];
      this.getStoreReport.filters.creatives = [];
      this.getStoreReport.filters.deal_id = [];
      this.getStoreReport.filters.layer_name = null;
      this.getStoreReport.filters.ooh_layer_name = null;
      this.getStoreReport.filters.ooh_distance = null;

      this.getStoreResources.advertisers = [];
      this.getStoreResources.campaigns = [];
      this.getStoreResources.creatives = [];
      this.getStoreResources.line_items = [];
      this.getStoreResources.deal_id = [];
      this.getStoreResources.layer_name = [];
      this.getStoreResources.ooh_layer_name = [];
      this.getStoreResources.ooh_distance = [];

      this.getStoreResources.setData("advertisers", []);
      this.getStoreResources.setData("campaigns", []);
      this.getStoreResources.setData("creatives", []);
      this.getStoreResources.setData("line_items", []);
      this.getStoreResources.setData("deal_id", []);
      this.getStoreResources.setData("layer_name", []);
      this.getStoreResources.setData("ooh_layer_name", []);
      this.getStoreResources.setData("ooh_distance", []);

      if (params?.dm) {
        this.getStoreResources.setData("report_metrics", []);
        this.getStoreResources.setData("report_dimensions", []);

        this.getStoreReport.dimension = [];
        this.getStoreReport.metric = [];

        this.getStoreReport.dimensions = {};
        this.getStoreReport.metrics = {};

        this.getStoreResources.dimensions = {};
        this.getStoreResources.metrics = {};
      }

      if (params?.account) {
        this.getStoreReport.filters.account = [];
        this.getStoreResources.setData("account", []);
      }
    },

    async handleClearFilter(params: any) {
      let values = this.filterEntitys[params].delete;
      if (!isEmpty(values)) {
        values.forEach(e => {
          this.getStoreResources.setData(e, []);
          this.getStoreReport.filters[e] = [];
        });
      }
    },

    async handleVerifyFilter(params: any) {
      const fieldsToCheck = ["advertisers", "campaigns", "creatives", "line_items", "deal_id", "layer_name", "metrics"];

      for (const field of fieldsToCheck) {
        if (!isEmpty(this.getStoreReport.filters[field]) && params !== field) {
          return false;
        }
      }

      return true;
    },

    async clearAllForm() {
      if (this.getStoreSchedule.form.hasID()) return;
      this.getStoreReport.filters.advertisers = [];
      this.getStoreReport.filters.line_items = [];
      this.getStoreReport.filters.campaigns = [];
      this.getStoreReport.filters.creatives = [];
      this.getStoreReport.filters.deal_id = [];
      this.getStoreReport.filters.layer_name = null;
      this.getStoreReport.filters.ooh_layer_name = null;
      this.getStoreReport.filters.ooh_distance = null;
      this.getStoreReport.dimension = [];
      this.getStoreReport.metric = [];
    },

    async clearAllFiltersSP() {
      if (this.getStoreSchedule.form.hasID()) return;
      this.getStoreReport.filters.account = [];
      this.getStoreReport.filters.advertisers = [];
      this.getStoreReport.filters.line_items = [];
      this.getStoreReport.filters.campaigns = [];
      this.getStoreReport.filters.creatives = [];
      this.getStoreReport.filters.deal_id = [];
      this.getStoreReport.filters.layer_name = null;
      this.getStoreReport.filters.ooh_layer_name = null;
      this.getStoreReport.filters.ooh_distance = null;
      this.getStoreReport.dimensions = {};
      this.getStoreReport.metrics = {};
    },

    async clearAllFormResource() {
      if (this.getStoreSchedule.form.hasID()) return;
      this.getStoreReport.filters.account = [];
      this.getStoreReport.filters.advertisers = [];
      this.getStoreReport.filters.line_items = [];
      this.getStoreReport.filters.campaigns = [];
      this.getStoreReport.filters.creatives = [];
      this.getStoreReport.filters.deal_id = [];
      this.getStoreReport.filters.layer_name = null;
      this.getStoreReport.filters.ooh_layer_name = null;
      this.getStoreReport.filters.ooh_distance = null;
      this.getStoreReport.dimension = [];
      this.getStoreReport.metric = [];
      this.getStoreResources.setData("report_dimensions", []);
      this.getStoreResources.setData("report_metrics", []);
    },

    async updateReport(params: any) {
      /**
       * Preparar datos para instanciar reporte
       */
      const dataParams = {
        report_type: params?.report_type_code,
        start_date: params?.start_date,
        end_date: params?.end_date,
        type: params?.file_type,
      };

      /**
       * Se crea la instancia de reporte con los parametros que se recibe
       */
      let reportData: ReportDataEntity = new ReportDataEntity(dataParams);
      this.setState({ key: "report", value: reportData });

      const dimensionData: string[] = getParsedData(params.dimensions);
      const metricData: string[] = getParsedData(params.metrics);
      const filtersData: FilterData = getParsedData(params.filters);
      if (!hasProperties(this.getStoreResources.report_dimensions)) {
        await this.fetchDimMet({ field_type: "report_dimensions" });
      }

      if (!hasProperties(this.getStoreResources.report_metrics)) {
        await this.fetchDimMet({ field_type: "report_metrics" });
      }

      /**
       * Se agregan las dimenciones al reporte
       */
      reportData.setData(
        "dimension",
        await getFilteredDimensions(this.getStoreResources.report_dimensions, dimensionData),
      );

      /**
       * Se agregan las metricas al reporte
       */
      reportData.setData("metric", await getFilteredMetrics(this.getStoreResources.report_metrics, metricData));

      /**
       * Se agrega la cuenta al reporte
       */
      if (!isEmpty(filtersData.account_id)) {
        reportData.setFilterData(
          "account",
          await getFilteredArray(filtersData.account_id, this.getStoreResources.account),
        );
      }

      if (this.isAccountMaster) {
        this.setLoadingField(true);
        await this.fetchFilters("advertisers", this.accountSelected);
        await this.fetchFilters("campaigns", this.accountSelected);
        await this.fetchFilters("line_items", this.accountSelected);
        await this.fetchFilters("creatives", this.accountSelected);
        this.setLoadingField(false);
      }

      /**
       * Se agregan advertisers al reporte
       */
      if (!isEmpty(filtersData.advertiser_id)) {
        reportData.setFilterData(
          "advertisers",
          await getFilteredSplits(filtersData.advertiser_id, this.getStoreResources.advertisers),
        );
      }

      /**
       * Se agregan campaigns al reporte
       */
      if (!isEmpty(filtersData.campaign_id)) {
        reportData.setFilterData(
          "campaigns",
          await getFilteredSplits(filtersData.campaign_id, this.getStoreResources.campaigns),
        );
      }

      /**
       * Se agregan line_items al reporte
       */
      if (!isEmpty(filtersData.line_item_id)) {
        reportData.setFilterData(
          "line_items",
          await getFilteredSplits(filtersData.line_item_id, this.getStoreResources.line_items),
        );
      }

      /**
       * Se agregan creatives al reporte
       */
      if (!isEmpty(filtersData.creative_id)) {
        reportData.setFilterData(
          "creatives",
          await getFilteredSplits(filtersData.creative_id, this.getStoreResources.creatives),
        );
      }

      //reportData.setFilterData("account", filtersData.account_id);

      /**
       * Se agrega el data_range al reporte
       */
      reportData.setData("data_range", filtersData.data_range);

      this.getStoreReport = reportData;

      if (!isProd()) {
        console.log(`Log no visible para prod (debug) ${this.$options.name}`, {
          dimensionData,
          metricData,
          filtersData,
          reportData,
        });
      }
    },

    async setItemSelected(params: any) {
      const reportR = this.getStoreReport[params.type];
      if (this.conditionalMetrics.hasOwnProperty(params.value.key)) {
        const selected = this.conditionalMetrics[params.value.key].selected;

        selected.forEach(c => {
          if (params.value.option) {
            this.getStoreReport[params.type][c] = 1;
          } else {
            if (this.getStoreReport[params.type].hasOwnProperty(c)) {
              delete this.getStoreReport[params.type][c];
            }
          }
        });
      }

      if (params.value.option) {
        this.$set(reportR, params.value.key, 1);
      } else {
        this.$delete(reportR, params.value.key);
      }
    },

    // Clear all fields differences after each account selection
    clearAllDifferences(key) {
      this.getStoreReport.filters[key] = this.getStoreReport.filters[key].filter(({ id: id_filter }) => {
        return this.getStoreResources[key].some(({ id: id_resource }) => {
          return id_resource == id_filter;
        });
      });
    },

    async enabledTabScheduler(disabled: Boolean = false) {
      this.items[1].disabled = disabled;
    },

    checkFields: debounce(async function (val, key) {
      this.clearAllDifferences(key);
    }, 250),

    handleCancel(val: number) {
      this.getStoreSchedule.resetForm();
      this.getStoreSchedule.form.resetForm();
      this.getStoreReport.setReportData();
      // if(val === 0) return;
      // this.getAttempResponse.setSuccess(false);
    },
    switchToSchedulerTab() {
      ///this.currentTab = 1; // Cambiar al índice de la pestaña de ReportScheduler
      
      this.$router.push({ 
          name: 'ListsMyReport'
      });
    },
  },
  watch: {
    "getStoreReport.data_range"(val: string, old: string) {
      this.clearAllFilters({ dm: false, account: false });
    },
  },
});
